<template>
    <modal :show="show"
           @close="close">
        <card type="secondary" shadow>
        <div class="overlay" v-if="submitting">
            <half-circle-spinner
                    :size="72"
                    :color="'#3f51b5'"
                    style="margin: auto;"
            />
        </div>
            <template>
                <button type="button" class="close" @click="close">×</button>
                <form role="form" class="needs-validation">
                  <div v-bind:key="tweet.count" v-for="tweet in tweets" class="row">
                  <div class="col-md-12">
                    <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
                      <textarea @keyup.ctrl.enter="saveGrowthProgramThreadAsADraft()"
                                rows="3"
                                class="form-control simple"
                                ref="tweet"
                                v-model="tweet.status"
                                autofocus
                                placeholder="Tweet"></textarea>
                      <div class="px-3 bg-white container pb-3">
                        <div class="tweet-info-box" style="bottom: 2rem; right: 2rem">
                          <div :class="charactersLeftClass(tweet.status)">
                           {{ charactersLeftCounter(tweet.status) }}
                           </div>
                        </div>
                      </div>
                    </base-input>
                  </div>
                  </div>
                    <div class="row">
                        <div class="d-none d-sm-none d-md-none d-xl-block d-lg-block
                                    col-lg-6 col-xl-6 col-xs-12 col-sm-12 col-md-12"></div>
                        <hr class="d-lg-none d-xl-none"/>

                        <div class="col-lg-6 col-xl-6 col-xs-12 col-sm-12 col-md-12
                                    container-fluid
                                    text-right">
                            <div class="row">
                              <button type="primary"
                                           class="px-1"
                                           :class="{'save-button': canFormBeSubmitted(), 'disabled-button': !canFormBeSubmitted()}"
                                           @click="saveGrowthProgramThreadAsADraft"
                                           style="width: 100%;"
                                           :disabled="!canFormBeSubmitted()">
                                <span v-if="!threadToEdit">Save Growth Program Tweet</span>
                                <span v-else>Edit Growth Program Tweet</span>
                              </button>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </card>
    </modal>
</template>

<script>
  import { countTweetLength } from "@/../functions/src/util/countTweetLength";
  import { HalfCircleSpinner } from 'epic-spinners'
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import {DraftThread} from "@/models/DraftThread";
  import ThreadMixin from "@/views/Mixins/ThreadMixin";

  export default {
    components: {
      HalfCircleSpinner,
    },
    data() {
      return this.initialState();
    },
    name: 'growth-thread-modal',
    props: {
      selectedGrowthProgram: Object,
      show: Boolean,
      selectedUser: Object,
      threadToEdit: Object,
    },
    methods: {
      charactersLeftCounter: function (status) {
        return 280 - countTweetLength(status);
      },
      charactersLeftClass(status) {
        if (countTweetLength(status) >= 280) return 'bgRed';
        if (countTweetLength(status) > 260) return 'bgYellow';
        return 'bgGray';
      },
      canFormBeSubmitted() {
        return countTweetLength(this.tweets[0].status) < 280 && !this.submitting &&
          this.tweets[0].status.trim().length;
      },
      close() {
        if (this.threadToEdit) {
          this.$emit('set-thread-to-edit-to-null');
          this.resetModal();
        }
        ThreadMixin.methods.close.bind(this)();
      },
      initialState() {
        return {
          tweets: [this.emptyTweet(0)],
          submitting: false,
        };
      },
      saveGrowthProgramThreadAsADraft: function () {
        const thread = new DraftThread({
          tweets: this.tweets,
          user: this.selectedUser.uid,
          source: 'growth-program',
          writer: this.userProfile.uid,
          growthProgram: this.selectedGrowthProgram.id,
        });
        
        return this.prepareThreadForSaving(thread);
      },
      prepareThreadForSaving(thread) {
        if (!this.canFormBeSubmitted()) return;

        this.submitting = true;
        if (this.threadToEdit) {
          thread.id = this.threadToEdit.id;
          return this.updateThread(thread, this);
        } else {
          if (this.selectedUser.areGrowthProgramThreadsFinished || this.selectedGrowthProgram.isPublishConfirmed) {
            this.swalModal({
              title: 'Warning',
              text: this.selectedGrowthProgram.isPublishConfirmed
                ? 'Growth program is already published'
                : 'Tweets for user are finished you need to delete some before saving more.',
              type: 'warning',            
            });
            this.resetModal();
            this.close();
          } else {
            return this.saveThread(thread, this);
          }
        }
      },
      saveThread: (thread, that) => {
          return thread.saveToFirestore(that.currentUser, that.userProfile.uid, that.userProfile.timezone, that.$eventStore)
            .then(result => {
              that.close();
              that.$notify({type: 'success', message : 'Growth program tweet saved.' });
              thread.id = result.id;

              that.$emit('reopen-growth-thread-modal');
              that.resetModal();
            })
            .catch(error => {
              console.error(error);
              alert('An error has occurred while saving the thread.')
            });
      },
      updateThread: (thread, that) => {
        return thread.update(that.currentUser, that.userProfile)
            .then(() => {
              that.close();
              that.$notify({type: 'success', message : 'Growth program tweet updated.' });
            })
            .catch(error => {
              console.error(error);
              alert('An error has occurred while updating the thread.')
            });
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    mixins: [ThreadMixin, SwalModalMixin],
    watch: {
      show(val) {
        if (!val) return;
        if (this.threadToEdit) {
          this.tweets = this.threadToEdit.tweets;
        }
        // this.focusOnLastTweetBox();
      },
    },
  }
</script>