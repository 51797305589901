<template>
  <modal
    :show="show"
    @close="close"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-sm"
  >
    <card type="secondary" shadow class="border-0 mb-0">
      <template>
        <div v-if="growthPrograms">
          <a
            v-for="growthProgram in growthPrograms"
            :key="growthProgram.date.seconds"
            class="list-group-item program"
            href="javascript:;"
            @click="switchToGrowthProgram(growthProgram)"
          >
            <div class="d-flex justify-content-between">
              {{ displayGrowthProgramDate(growthProgram.date) }}
            </div>
          </a>
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import GrowthProgramMixin from '@/views/Mixins/GrowthProgramMixin';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    mounted() {
      this.getGrowthPrograms();
    },
    data() {
      return {
        growthPrograms: null,
      };
    },
    name: 'growth-program-list-modal',
    props: {
      show: Boolean,
    },
    methods: {
      close() {
        this.$emit('close');
      },
      switchToGrowthProgram(growthProgram) {
        this.$emit('set-selected-growth-program', growthProgram);
        this.close();
      },
    },
    mixins: [GrowthProgramMixin],
    watch: {},
  };
</script>

<style type="scss"></style>
