<template>
    <div class="growth-program-old-style">
        <back-to-top-custom />

        <div v-if="selectedUser" class="container-fluid mt--4">
          <div class="row justify-content-center mb-3">
            <div class="col-12 col-lg-8 floating-button-section queue-header">
              <card :no-body="true" style="cursor: pointer;" class="mb-0">
                <div class="card-body" @click="showGrowthModal">
                  <div class="row align-items-center">
                    <div class="col-sm-9">
                      <p class="large mb-0 pr-5">Write a tweet for {{selectedUser.username}}</p>
                    </div>
                  </div>
                </div>
              </card>

              <base-dropdown
                class="floating-button"
                menuClasses="tools-dropdown-menu dropdown-menu-right"
              >
                <base-button class="mx-0" slot="title-container" type="secondary" size="lg">
                  <i class="fas fa-ellipsis-v"></i>
                </base-button>
                <a class="dropdown-item" href="javascript:;" @click="showGrowthProgramUsersModal">
                  <i class="icon-light fas fa-users"></i>
                  Users
                </a>
                <a class="dropdown-item" href="javascript:;" @click="showGrowthProgramListModal">
                  <i class="icon-light ni ni-ungroup"></i>
                  Growth Programs
                </a>
                <!-- <a class="dropdown-item" href="javascript:;" @click="openCSVUploadModal">
                  <i class="icon-light ni ni-ungroup"></i>
                  CSV Upload
                </a> -->
              </base-dropdown>
            </div>
          </div>
        </div>

        <div v-if="selectedUser && drafts" class="text-center mb-4">
          <div v-if="!areAllCustomersGrowthProgramThreadsFinished()">
            <badge :type="selectedUser.areGrowthProgramThreadsFinished ? 'danger' : 'info'" class="mr-4">
              {{displayTweetsStatusForUser(this.selectedUser)}}
            </badge>
            <a :href="`https://www.twitter.com/${this.selectedUser.username}`" target="_blank" class="btn btn-primary">Visit user's profile</a>
          </div>
          <div v-else>
            <div v-if="!selectedGrowthProgram.isPublishConfirmed">
              <h3>All tweets are composed. Do you want to publish them?</h3>
              <base-button type="primary" @click="publishThreads">{{ isLoading ? 'Publishing...' : 'Publish'}}</base-button>
            </div>
            <div v-else>
              <h3>Tweets are already published for this growth program</h3>
            </div>
          </div>
        </div>

        <div class="container-fluid" v-if="drafts && !selectedGrowthProgram.isPublishConfirmed && !isLoading">
            <div class="row justify-content-center">
                <div class="col-lg-8 justify-content-center">
                    <div v-bind:key="draft.id" class="" v-for="draft in drafts">
                        <card :no-body="true" v-if="draft.tweets !== undefined">
                            <div class="card-body container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="lead mt-0 card-text white-space-pre">{{ draft.tweets[0].status }}</p>
                                    </div>
                                </div>
                            </div>

                            <div slot="footer" class="row">
                                <div class="text-right col-12">
                                    <p class="mb-0">
                                        <small>
                                            <button href="javascript:;"
                                                         size="sm"
                                                         class="edit-button"
                                                         type="secondary"
                                                         @click="editThread(draft)">
                                                Edit
                                            </button>
                                            <button href="javascript:;"
                                                    type="secondary"
                                                    class="delete-button"
                                                    @click="deleteGrowthProgramThread(draft)">
                                                Delete
                                            </button>
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <UploadCSVModal :show="isCSVUploadModalVisible"
                            @close="closeCSVUploadModal"
                            :isGrowthPage="true"
                            :selectedUser="selectedUser"
                            :selectedGrowthProgram="selectedGrowthProgram" />

            <growth-thread-modal @close="closeGrowthModal"
                                 :show="isGrowthModalVisible"
                                 @reopen-growth-thread-modal="reOpenGrowthModal"
                                 :selectedUser="selectedUser"
                                 :selectedGrowthProgram="selectedGrowthProgram"
                                 :threadToEdit="threadToEdit"
                                 @set-thread-to-edit-to-null="setThreadToEditToNull" />

            <growth-program-users-modal @close="closeGrowthProgramUsersModal"
                               :show="isGrowthProgramUsersModalVisible"
                               @set-selected-user="setSelectedUser"
                               :selectedUser="selectedUser"
                               :selectedGrowthProgram="selectedGrowthProgram" />

            <growth-program-list-modal @close="closeGrowthProgramListModal"
                                       :show="isGrowthProgramListModalVisible"
                                       @set-selected-growth-program="switchToGrowthProgram" />
        </div>
    </div>
</template>

<script>
  import moment from 'moment';
  import 'moment-timezone';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import GrowthThreadModal from '@/views/Modals/GrowthProgram/GrowthThreadModal';
  import GrowthProgramListModal from '@/views/Modals/GrowthProgram/GrowthProgramListModal';
  import GrowthProgramUsersModal from '@/views/Modals/GrowthProgram/GrowthProgramUsersModal';
  import UploadCSVModal from '@/views/Modals/UploadCSVModal';
  import {DraftThread} from '@/models/DraftThread';
  import DeletePostMixin from "@/views/Mixins/DeletePostMixin";
  import GrowthProgramMixin from "@/views/Mixins/GrowthProgramMixin";
  import BackToTopCustom from "@/views/Widgets/BackToTopCustom";
  import { store } from '@/store';
  import '@/assets/sass/growthprogram.old.scss';
  const fb = require('@/firebase');

  export default {
    name: 'GrowthProgram',
    components: {
      BackToTopCustom,
      GrowthThreadModal,
      GrowthProgramListModal,
      GrowthProgramUsersModal,
      UploadCSVModal,
    },
    data() {
      return {
        selectedGrowthProgram: null,
        drafts: null,
        isCSVUploadModalVisible: false,
        isGrowthModalVisible: false,
        isGrowthProgramUsersModalVisible: true,
        isLoading: false,
        isWriterPage: true,
        selectedUser: null,
        threadToEdit: null,
        unsubscribeFromGrowthProgram: null,
      };
    },
    created () {
      this.getCurrentGrowthProgram();
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      closeCSVUploadModal: function () {
        this.isCSVUploadModalVisible = false;
      },
      areAllCustomersGrowthProgramThreadsFinished() {
        return (
          this.selectedGrowthProgram.customers.length ===
          this.selectedGrowthProgram.customersTweetsFinished.length
        );
      },
      deleteGrowthProgramThread(thread) {
        const wereUserGrowthProgramThreadsFinished = () => {
          return this.selectedUser.numberOfGrowthSubProgramThreads - 1 === this.selectedUser.numberOfTweets;
        };

        this.deleteThread(thread).then((res) => {
          const isDeleteThreadDismissed = res.dismiss;
          if (!isDeleteThreadDismissed) {
            if (wereUserGrowthProgramThreadsFinished()) {
              dao.growthProgram.removeCustomerFromCustomersTweetsFinished(this.selectedGrowthProgram.id, this.selectedUser);
            }
          }
        });
      },
      editThread(thread) {
        this.threadToEdit = thread;
        this.showGrowthModal();
      },
      publishThreads() {
        const displayPublishConfirm = () => {
          return this.swalModal({
            title: 'Publish tweets',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            text: 'Are you sure you want to publish the tweets?',
          }).then(result => !!result.value);
        };

        displayPublishConfirm().then((isItConfirmed) =>
          isItConfirmed
            ? dao.growthProgram
                .confirmGrowthProgramPublishing(this.selectedGrowthProgram.id)
                .then(() =>
                  this.$notify({ type: 'success', message: 'Tweets published successfully!' })
                )
            : null
        );
      },
      updateUserNumberOfTweets() {
        this.selectedUser.numberOfTweets = this.drafts.length;
        this.selectedUser.areGrowthProgramThreadsFinished = this.drafts.length === this.selectedUser.numberOfGrowthSubProgramThreads;
      },
      updateDraftsList() {
        if (!this.selectedUser) return;

        const userRef = fb.usersCollection.doc(this.selectedUser.uid);

        const that = this;

        const unsubscribe = fb.threadsCollection
          .where('deleted', '==', false)
          .where('user', '==', userRef)
          .where('time', '==', null)
          .where('growthProgram', '==', this.selectedGrowthProgram.ref)
          .where('source', '==', 'growth-program')
          .onSnapshot((doc) => {
            that.drafts = doc.docs.map(d => {
              return new DraftThread({
                id: d.id,
                tweets: d.data().tweets,
                user: d.data().user,
              });
            });
            that.updateUserNumberOfTweets();
          });

          store.dispatch('addFirestoreListener', unsubscribe);
      },
      updateSelectedGrowthProgram(growthProgramId) {
        if (this.unsubscribeFromGrowthProgram) {
          this.unsubscribeFromGrowthProgram();
        }
        this.unsubscribeFromGrowthProgram = fb.growthProgramsCollection
          .doc(growthProgramId)
          .onSnapshot((updatedGrowthProgram) => {
            this.selectedGrowthProgram = {
              ref: updatedGrowthProgram.ref,
              id: updatedGrowthProgram.id,
              ...updatedGrowthProgram.data(),
            };
          });

        store.dispatch('addFirestoreListener', this.unsubscribeFromGrowthProgram);
      },
      setThreadToEditToNull() {
        this.threadToEdit = null;
      },
      openCSVUploadModal() {
        this.isCSVUploadModalVisible = true;
      },
      reOpenGrowthModal() {
        if (this.selectedUser.areGrowthProgramThreadsFinished) {
          this.$notify({type: 'success', message: 'User tweets are finished!'});
          this.showGrowthProgramUsersModal();
          dao.growthProgram.addCustomerToCustomersTweetsFinished(this.selectedGrowthProgram.id, this.selectedUser);
        } else {
          setTimeout(() => this.showGrowthModal(), 400);
        }
      },
    },
    mixins: [DeletePostMixin, GrowthProgramMixin, SwalModalMixin],
    watch: {
      selectedUser(val) {
        if (!val) return;
        this.updateDraftsList();
      },
    },
  };
</script>

<style scoped>
</style>
